// Script1.js
document.addEventListener('DOMContentLoaded', function() {

  function mettreAJourHeure() {
    const heureLausanne = document.getElementById("heure");
    const date = new Date();
    const options = {
      timeZone: "Europe/Zurich",
      hour: "numeric",
      minute: "numeric",
      second: "numeric" // Ajout de "second" : "numeric"
    };
    heureLausanne.textContent = new Intl.DateTimeFormat("fr-CH", options).format(
      date
    );
  }
  
  mettreAJourHeure(); // Appel initial pour afficher l'heure sans délai
  setInterval(mettreAJourHeure, 1000); // Actualisation toutes les secondes
});

const playPauseButton = document.getElementById("play-pause-button");
const audioPlayer = document.getElementById("music");
const volumeSlider = document.getElementById("volume-slider");


playPauseButton.addEventListener("click", () => {
  if (audioPlayer.paused) {
    audioPlayer.play();
    playPauseButton.innerHTML = "&#10074;&#10074;";
  } else {
    audioPlayer.pause();
    playPauseButton.innerHTML = "&#9658;";
  }
});
